$small-screen: 640px;
$med-screen: 768px;
$large-screen: 1024px;
$xl-screen: 1280px;

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding: 0;
  margin: 0;
  background-color: #585d5e;
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.name-heading {
  font-size: 1.25rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0 0.5rem;
  font-weight: normal;
  padding: 0;
  text-shadow: 0 3px 0 #624776;
}

.social-links {
  img {
    height: 1.25rem;
    padding: 0 0.5rem;
    margin: 0;
  }

  a {
    display: inline-block;
  }

  a + a {
    border-left: 1px solid rgb(204, 204, 204);
    box-sizing: content-box;
  }
}

.hidden {
  text-indent: -9999em;
  outline: 0;
}

.site-banner {
  background-color: #815b97;
  height: 2.25rem;
  padding: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem;
}

.splash-screen {
  height: calc(100vh - 4.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome {
  text-align: center;
  font-family: "Inconsolata", monospace;

  p {
    font-size: 0.7rem;

    @media (min-width: $med-screen) {
      font-size: 1.5rem;
    }

    @media (min-width: $large-screen) {
      font-size: 2rem;
    }
  }
}

#hello-code {
  overflow: hidden;
  white-space: nowrap;
  font-size: 1.2rem;

  @media (min-width: $med-screen) {
    font-size: 3.2rem;
  }

  @media (min-width: $large-screen) {
    font-size: 4rem;
  }
}

.end-footer {
  padding: 0.25rem;
  height: 2.25rem;
  position: relative;
  background-color: #3994a8;
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.5), 0 -1px 2px 0 rgba(0, 0, 0, 0.08);
}

.token.boolean,
.token.number,
.token.function {
  color: #6196cc !important;
}
