* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding: 0;
  margin: 0;
  background-color: #585d5e;
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.name-heading {
  font-size: 1.25rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0 0.5rem;
  font-weight: normal;
  padding: 0;
  text-shadow: 0 3px 0 #624776;
}

.social-links img {
  height: 1.25rem;
  padding: 0 0.5rem;
  margin: 0;
}
.social-links a {
  display: inline-block;
}
.social-links a + a {
  border-left: 1px solid #cccccc;
  box-sizing: content-box;
}

.hidden {
  text-indent: -9999em;
  outline: 0;
}

.site-banner {
  background-color: #815b97;
  height: 2.25rem;
  padding: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem;
}

.splash-screen {
  height: calc(100vh - 4.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome {
  text-align: center;
  font-family: "Inconsolata", monospace;
}
.welcome p {
  font-size: 0.7rem;
}
@media (min-width: 768px) {
  .welcome p {
    font-size: 1.5rem;
  }
}
@media (min-width: 1024px) {
  .welcome p {
    font-size: 2rem;
  }
}

#hello-code {
  overflow: hidden;
  white-space: nowrap;
  font-size: 1.2rem;
}
@media (min-width: 768px) {
  #hello-code {
    font-size: 3.2rem;
  }
}
@media (min-width: 1024px) {
  #hello-code {
    font-size: 4rem;
  }
}

.end-footer {
  padding: 0.25rem;
  height: 2.25rem;
  position: relative;
  background-color: #3994a8;
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.5), 0 -1px 2px 0 rgba(0, 0, 0, 0.08);
}

.token.boolean,
.token.number,
.token.function {
  color: #6196cc !important;
}
pre[class*="language-"].line-numbers {
	position: relative;
	padding-left: 3.8em;
	counter-reset: linenumber;
}

pre[class*="language-"].line-numbers > code {
	position: relative;
	white-space: inherit;
}

.line-numbers .line-numbers-rows {
	position: absolute;
	pointer-events: none;
	top: 0;
	font-size: 100%;
	left: -3.8em;
	width: 3em; /* works for line-numbers below 1000 lines */
	letter-spacing: -1px;
	border-right: 1px solid #999;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

}

	.line-numbers-rows > span {
		display: block;
		counter-increment: linenumber;
	}

		.line-numbers-rows > span:before {
			content: counter(linenumber);
			color: #999;
			display: block;
			padding-right: 0.8em;
			text-align: right;
		}

/**
 * prism.js tomorrow night eighties for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/chriskempson/tomorrow-theme
 * @author Rose Pritchard
 */

code[class*="language-"],
pre[class*="language-"] {
	color: #ccc;
	background: none;
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	font-size: 1em;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.5;

	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;

}

/* Code blocks */
pre[class*="language-"] {
	padding: 1em;
	margin: .5em 0;
	overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
	background: #2d2d2d;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
	padding: .1em;
	border-radius: .3em;
	white-space: normal;
}

.token.comment,
.token.block-comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: #999;
}

.token.punctuation {
	color: #ccc;
}

.token.tag,
.token.attr-name,
.token.namespace,
.token.deleted {
	color: #e2777a;
}

.token.function-name {
	color: #6196cc;
}

.token.boolean,
.token.number,
.token.function {
	color: #f08d49;
}

.token.property,
.token.class-name,
.token.constant,
.token.symbol {
	color: #f8c555;
}

.token.selector,
.token.important,
.token.atrule,
.token.keyword,
.token.builtin {
	color: #cc99cd;
}

.token.string,
.token.char,
.token.attr-value,
.token.regex,
.token.variable {
	color: #7ec699;
}

.token.operator,
.token.entity,
.token.url {
	color: #67cdcc;
}

.token.important,
.token.bold {
	font-weight: bold;
}
.token.italic {
	font-style: italic;
}

.token.entity {
	cursor: help;
}

.token.inserted {
	color: green;
}

/*# sourceMappingURL=index.a5524d16.css.map */
